<template>
  <div class="cs-section">
    <div class="section-header">
      <h2 class="section-title">{{ getHeader.title }}</h2>
      <p class="section-description">
        {{ getHeader.description }}
      </p>
    </div>
    <div class="section-body" v-if="getCards.length">
      <section :class="getCardClasses(card.meta.id)" v-for="card in getCards" :key="card.meta.id">
        <div class="card-container">
          <img v-if="card.logo_url" :src="card.logo_url" :alt="card.logo_alt_text" class="logo-img">
          <h3 class="card-title">{{ card.card_title }}</h3>
          <p class="card-description">
            {{ card.card_description }}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Array,
      default() {
        return [];
      },
    },
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getCards() {
      return this.cardData;
    },
    getHeader() {
      return this.headerData;
    },
  },
  methods: {
    getCardClasses(customClass) {
      const carClassArr = ['card'];
      carClassArr.push(`card-${customClass}`);
      return carClassArr;
    },
  },
};
</script>

<style lang="scss" scoped="">
@import '../../styles/global/marketing.scss';

.section-header {
  @include section-title-framework();
}

.section-title {
  @extend %section-title-font;
  padding-left: 0;
  text-align: left;
  margin-bottom: 0.5rem;
}

.section-description {
  @include card-description-font();
  max-width: 47.5rem;

  line-height: 1.5rem;
  @include bp-xlarge {
    line-height: 2.0625rem;
  }
}

.card {
  @include card-bg-color(#fff);
  h3 {
    margin: 0;
  }
  border-radius: 1rem;
  @include bp-mediumsmall {
    border-radius: 1.25rem;
  }

  @include bp-xlarge {
    border-radius: 2rem;
  }
}

.card-title {
  @include card-title-font();

  color: $section-primary;
  margin-bottom: 0.5rem;

  @include bp-xlarge {
    margin-bottom: 1rem;
  }
}

.card-description {
  color: #000;
  @include card-description-font();
}

.section-body {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.section-body .card:nth-child(7) {
  @include bp-xs {
    height: 35rem;
  }
  @include bp-medium {
    height: auto;
  }
  @include bp-mediumlarge {
    height: 37.875rem;
  }
  @include bp-xlarge {
    height: auto;
  }
  @include bp-large {
    height: 65.625rem;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  align-items: center;
  h3 {
    margin-top: 2.5rem;
    grid-row-start: 2;
    grid-column-end: 2 span;
    color: #293447;
  }
  picture {
    grid-row-start: 1;
  }
  p {
    grid-row-start: 3;
  }
  .card-description {
    grid-row-start: 3;
    grid-column-end: span 2;
    margin-top: 1rem;
  }
  @include bp-xs {
    padding: 1rem;
  }
  @include bp-small {
    padding: 2.5rem 1.5rem;
  }
  @include bp-large {
    padding: 2.5rem 4rem;
  }
}
.logo-img {
  margin-right: 1rem;
  @include bp-xs {
    height: 1rem;
  }
  @include bp-small {
    height: 2rem;
  }
  @include bp-medium {
    height: 3rem;
  }
}
// Florida
.card-167041 {
  align-items: center;
  display: grid;
  .card-container {
    @include bp-xs {
      padding: 1rem;
    }
    @include bp-small {
      padding: 2.5rem 1.5rem;
    }
    @include bp-medium {
      padding: 2.5rem;
    }
  }
  @include bp-xs {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  @include bp-mediumlarge {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: unset;
  }
}
//Colorado
.card-250862 {
  .card-container {
    @include bp-xs {
      padding: 1rem;
    }
    @include bp-small {
      padding: 2.5rem 1.5rem;
    }
    @include bp-medium {
      padding: 2.5rem;
    }
  }
  @include bp-xs {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  @include bp-mediumlarge {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: unset;
  }
}
// Live Nation
.card-250865 {
  align-items: center;
  display: grid;
  .card-container {
    @include bp-xs {
      padding: 1rem;
    }
    @include bp-small {
      padding: 2.5rem 1.5rem;
    }
    @include bp-medium {
      padding: 2.5rem;
    }
  }
  @include bp-xs {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  @include bp-mediumlarge {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: unset;
  }
}
// Oklahoma University
.card-167043 {
  align-items: center;
  display: grid;
  .card-container {
    @include bp-xs {
      padding: 1rem;
    }
    @include bp-small {
      padding: 2.5rem 1.5rem;
    }
    @include bp-medium {
      padding: 2.5rem;
    }
  }
  @include bp-xs {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  @include bp-mediumlarge {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: unset;
  }
}
.banner-img {
  height: 100%;
  max-height: 19.5rem;
  display: none;
  @include bp-medium {
    display: block;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    max-height: 20rem;
  }
  @include bp-large {
    max-height: 19.5rem;
  }
  @include bp-xlarge {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
}
.card-banner {
  overflow: hidden;
}
</style>
