<template>
  <div class="impact-section">
    <div class="section-header">
      <h2 class="section-title">{{ getHeader.title }}</h2>
      <p class="section-description">
        {{ getHeader.description }}
      </p>
    </div>
    <div class="section-body" v-if="getCards.length">
      <section :class="getCardClasses(card.meta.id)" v-for="card in getCards" :key="card.meta.id">
        <h3 class="card-title" v-if="card.meta.id !== 136956">
          {{ card.card_title }}
        </h3>
        <p class="card-description" v-if="card.meta.id !== 136956">
          {{ card.card_description }}
        </p>
        <img v-if="card.image_url" :src="card.image_url" :alt="card.image_alt_text" class="feature-img">
        <div class="ratings" v-if="card.meta.id === 136956">
          <div class="rate-total">{{ card.ratings }}</div>
          <div class="rate-stars-wrapper">
            <div class="stars">
              <i class="star"></i>
              <i class="star"></i>
              <i class="star"></i>
              <i class="star"></i>
              <i class="star"></i>
            </div>
            <p class="rate-nums">{{ card.rating_count }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Array,
      default() {
        return [];
      },
    },
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getCards() {
      return this.cardData;
    },
    getHeader() {
      return this.headerData;
    },
  },
  methods: {
    getCardClasses(customClass) {
      const carClassArr = ['card'];
      carClassArr.push(`card-${customClass}`);
      return carClassArr;
    },
  },
};
</script>

<style lang="scss" scoped="">
@import '../../styles/global/marketing.scss';

.ratings {
  background: #f2f1f6;
  border: 4px solid #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(8, 35, 48, 0.1);
  border-radius: 1rem;
  display: flex;
  position: absolute;
  left: 1rem;
  bottom: 1.5rem;
  width: calc(100% - 2rem);
}

.rate-total {
  width: 4rem;
  height: 4rem;
  margin: 1rem;
  background: #e21858;
  border-radius: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #fff;

  @include flex-center();
}

.star {
  @include star(#edc100);
  width: 1.25rem;
}

.stars {
  display: flex;
  justify-content: space-between;
  max-width: 8rem;
}

.rate-nums {
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  font-weight: 500;

  @include bp-xlarge {
    font-size: 1.0625rem;
    line-height: 1.4875rem;
  }
}

.rate-stars-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-header {
  @include section-title-framework();
}

.section-title {
  @extend %section-title-font;
  padding-left: 0;
  text-align: left;
  margin-bottom: 0.5rem;
}

.section-description {
  @include card-description-font();
  max-width: 47.5rem;

  line-height: 1.5rem;
  @include bp-xlarge {
    line-height: 2.0625rem;
  }
}

.card {
  @include card-basic();
}

.card-title {
  @include card-title-font();

  color: $section-primary;
  margin-bottom: 0.5rem;

  @include bp-xlarge {
    margin-bottom: 1rem;
  }
}

.card-description {
  color: #000;
  @include card-description-font();
}

.section-body {
  display: grid;
  gap: 0.5rem;

  @include bp-medium {
    grid-template-columns: repeat(2, auto) 21.4375rem;
  }

  @include bp-xlarge {
    grid-template-columns: repeat(2, auto) 23.4375rem;
    gap: 1.5rem;
  }

  @include bp-large {
    grid-template-columns: repeat(2, auto) 29.4375rem;
    gap: 1.5rem;
  }
}

.section-body .card:nth-child(7) {
  @include bp-xs {
    height: 35rem;
  }
  @include bp-medium {
    height: auto;
  }
  @include bp-mediumlarge {
    height: 37.875rem;
  }
  @include bp-xlarge {
    height: auto;
  }
  @include bp-large {
    height: 65.625rem;
  }
}

.card-166439 {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-template-columns: 1fr 2.2fr;
  align-items: center;
  display: grid;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
  grid-column-gap: 1.5rem;
  h3 {
    margin: 0;
  }
  @include bp-xs {
    h3 {
      font-size: 2.25rem;
      line-height: 2.7rem;
    }
  }
  @include bp-mediumsmall {
    h3 {
      font-size: 3.5rem;
      line-height: 4.2rem;
    }
  }
  @include bp-medium {
    h3 {
      font-size: 3rem;
      line-height: 3.9rem;
    }
    padding-bottom: 2.35rem;
    padding-top: 2.35rem;
  }
  @include bp-large {
    h3 {
      font-size: 4.25rem;
      line-height: 5.1rem;
    }
  }
  @include bp-xlarge {
    grid-column-gap: 0;
    grid-column-end: span 2;
  }
}

.card-166588 {
  grid-row-start: 2;
  grid-template-columns: 1fr 2.2fr;
  align-items: center;
  display: grid;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
  grid-column-gap: 1.5rem;
  h3 {
    margin: 0;
  }
  @include bp-xs {
    h3 {
      font-size: 2.25rem;
      line-height: 2.7rem;
    }
  }
  @include bp-mediumsmall {
    h3 {
      font-size: 3.5rem;
      line-height: 4.2rem;
    }
  }

  @include bp-medium {
    h3 {
      font-size: 3rem;
      line-height: 3.9rem;
    }
    padding-bottom: 2.35rem;
    padding-top: 2.35rem;
  }

  @include bp-large {
    h3 {
      font-size: 4.25rem;
      line-height: 5.1rem;
    }
  }

  @include bp-xlarge {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-column-gap: 0;
  }
}

.card-166589 {
  grid-row-start: 3;
  grid-template-columns: 1fr 2.2fr;
  align-items: center;
  display: grid;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
  grid-column-gap: 1.5rem;
  h3 {
    margin: 0;
  }
  @include bp-xs {
    h3 {
      font-size: 2.25rem;
      line-height: 2.7rem;
    }
  }
  @include bp-mediumsmall {
    h3 {
      font-size: 3.5rem;
      line-height: 4.2rem;
    }
  }
  @include bp-medium {
    h3 {
      font-size: 3rem;
      line-height: 3.9rem;
    }
    padding-bottom: 2.35rem;
    padding-top: 2.35rem;
  }
  @include bp-large {
    h3 {
      font-size: 4.25rem;
      line-height: 5.1rem;
    }
  }
  @include bp-xlarge {
    grid-column-end: span 2;
    grid-column-gap: 0;
    gap: 1.5rem;
  }
}

.card-136956 {
  // order: 4;
  // Top Ranked Health App
  grid-row-start: 4;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0.5rem;
  position: relative;
  overflow: hidden;

  @include bp-medium {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: span 6;
    max-height: 27.54rem;
  }

  @include bp-xlarge {
    max-height: 28.8rem;
  }

  @include bp-large {
    max-height: 32.5rem;
  }

  .card-title,
  .card-description {
    padding-right: 1.5rem;
  }

  .feature-img {
    display: block;

    margin-top: 2rem;
    max-width: 18.25rem;
    width: 100%;
    margin-left: 0.5rem;
    margin-right: auto;
    filter: drop-shadow(24px 20px 30px rgba(0, 0, 0, 0.25));
    background: #000;

    @include bp-xs {
      margin-left: 2.9vw; 
      max-width: 71vw;
      border-top-left-radius: 11.1vw; 
      border-top-right-radius: 11.1vw; 
    }
    @include bp-xsm {
      margin-left: 5.9vw; 
      max-width: 70vw;
      border-top-left-radius: 10.2vw; 
      border-top-right-radius: 10.2vw; 
    }
    @include bp-mediumsmall {
      margin-left: 2.4rem;
      max-width: 20rem;
      border-top-left-radius: 3rem;
      border-top-right-radius: 3rem;
    }
    @include bp-medium {
      margin-left: 0.45rem;
      border-top-left-radius: 2.6rem;
      border-top-right-radius: 2.6rem;
      max-width: 17.5rem;
    }
    @include bp-xlarge {
      margin-left: 0;
      max-width: 19rem;
      margin-right: 0;
      border-top-left-radius: 2.8rem;
      border-top-right-radius: 2.8rem;
      filter: drop-shadow(24px 20px 30px rgba(0, 0, 0, 0.18));
      background: #000;
    }
    @include bp-large {
      max-width: 20.5rem;
      margin-top: 1.725rem;
      margin-left: 2.2rem;
      border-top-left-radius: 3.1rem;
      border-top-right-radius: 3.1rem;
      width: 100%;
      filter: drop-shadow(24px 20px 30px rgba(0, 0, 0, 0.18));
      background: #000;
    }
  }
}
</style>
